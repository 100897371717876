<template>
  <div class="home" v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px">
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row v-if="messageSent == false" class="rounded_row_white black--text pa-2 pb-12">
              <v-col cols="12">
                <div v-if="!no_header" style="max-width: 320px" class="mb-4">
                  <h2>
                    Hai bisogno di supporto informatico, sei interessato allo
                    sviluppo di soluzioni WEB e applicative o di altri servizi?
                  </h2>
                </div>
                <h3>
                  <strong>
                    <vue-typer :text="['Entra in contatto con AVMSquare!']" :repeat="0" :shuffle="false"
                      initial-action="typing" :pre-type-delay="0" :type-delay="20" :pre-erase-delay="2000"
                      :erase-delay="250" erase-style="select-all" :erase-on-complete="false" caret-animation="solid"
                      class="heading"></vue-typer>
                  </strong>
                </h3>
                <br />
                <vue-typer :text="[
                  'Lascia un messaggio e verrai ricontattato nella modalità che preferisci.',
                ]" :repeat="0" :shuffle="false" initial-action="typing" :pre-type-delay="1500" :type-delay="20"
                  :pre-erase-delay="2000" :erase-delay="250" erase-style="select-all" :erase-on-complete="false"
                  caret-animation="solid"></vue-typer>
                <br />
                <br />
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field label="Nome" v-model="nome" dense outlined :error-messages="err.nome">
                    </v-text-field>

                    <v-text-field v-if="isAzienda" label="Azienda" v-model="azienda" dense outlined
                      :error-messages="err.azienda">
                    </v-text-field>

                    <v-checkbox v-model="isAzienda" label="" dense class="mt-0">
                      <template v-slot:label>
                        <div>
                          <span style="font-size: 0.8rem">Scrivo per conto di un azienda</span>
                        </div>
                      </template>
                    </v-checkbox>

                    Preferisco essere contatto via:

                    <v-radio-group class="mt-0 pt-2" v-model="contact_type" row dense>
                      <v-row>
                        <v-col cols="4" class="d-flex align-center justify-center">
                          <v-radio label="Email" value="Email" color="primary">
                            <template v-slot:label>
                              <div>
                                <v-icon color="red">mdi-email-edit</v-icon>
                                <strong>
                                  <span v-if="$vuetify.breakpoint.name != 'xs'" class="ml-2 caption">Email</span>
                                </strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-col>
                        <v-col cols="4" class="d-flex align-center justify-center">
                          <v-radio label="Telefono" value="Telefono" color="primary">
                            <template v-slot:label>
                              <div>
                                <v-icon color="blue">mdi-phone</v-icon>
                                <strong>
                                  <span v-if="$vuetify.breakpoint.name != 'xs'" class="ml-2 caption">Telefono</span>
                                </strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-col>
                        <v-col cols="4" class="d-flex align-center justify-center">
                          <v-radio label="Whatsapp" value="Whatsapp" color="primary">
                            <template v-slot:label>
                              <div>
                                <v-icon small color="green">mdi-whatsapp</v-icon>
                                <strong>
                                  <span v-if="$vuetify.breakpoint.name != 'xs'" class="ml-2 caption">Whatsapp</span>
                                </strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-if="contact_type == 'Email'" label="Email" v-model="email" dense outlined
                      :error-messages="err.email">
                    </v-text-field>

                    <v-text-field v-if="
                      contact_type == 'Telefono' || contact_type == 'Whatsapp'
                    " label="Numero di telefono" v-model="telefono" dense outlined :error-messages="err.telefono">
                    </v-text-field>

                    <v-select v-model="topic" :items="topics" label="Sono interessato a..." :error-messages="err.topic"
                      outlined dense></v-select>

                    <v-textarea outlined name="" :label="label_messaggio" v-model="messaggio" value=""
                      :error-messages="err.messaggio" rows="4"></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12">
                    <v-checkbox v-model="altreComunicazioni" label="" dense class="mt-0">
                      <template v-slot:label>
                        <div>
                          <span style="font-size: 0.8rem">Desidero ricevere ulteriori comunicazioni da
                            AVMSquare</span>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-btn block :disabled="disableSend" height="50" color="primary" @click="validate()">
                  {{ btn_send }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="messageSent == true" class="rounded_row_black black--text pa-2 pb-12">
              <v-col cols="12">
                <span>
                  <h1 class="white--text">Grazie!</h1>
                </span>
                <br />
                <h2 class="white--text">Il tuo messaggio è stato inviato.</h2>
                <br />
                <br />
                <h3 class="white--text">Verrai contattato al più presto.</h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BreadCrumbs from "../components/BreadCrumbs";
import { VueTyper } from "vue-typer";
import { firebaseFunctions } from "../plugins/firebase";
import { gtag } from "@/gtag";

// import { analytics } from "../plugins/firebase";
const axios = require("axios");

export default {
  metaInfo: {
    title: "Contattaci",
    meta: [
      {
        name: "description",
        content: "Entra in contatto con AVMSquare, siamo qui per aiutarti.",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },

  components: {
    BreadCrumbs,
    VueTyper,
  },
  data: () => ({
    no_header: false,
    messageSent: false,
    disableSend: false,
    nome: "",
    isAzienda: false,
    azienda: "",
    email: "",
    telefono: "",
    contact_type: "Email",
    messaggio: "",
    altreComunicazioni: false,
    topic: "",
    ipdata: null,
    err: {
      nome: null,
      azienda: null,
      email: null,
      telefono: null,
      contact_type: null,
      messaggio: null,
      topic: null,
    },
    btn_send: "Invia",
    topics: [
      {
        text: "Assistenza informatica",
        value: "support_user", // <<<
      },
      {
        text: "Assistenza IT",
        value: "support_it", // <<<
      },
      {
        text: "Protezione & Firewall",
        value: "firewall", // <<<
      },
      {
        text: "Sicurezza informatica",
        value: "security", // <<<
      },
      {
        text: "Backup e protezione dati",
        value: "backup", // <<<
      },
      {
        text: "Integrazione Cloud",
        value: "cloud", // <<<
      },
      {
        text: "Analisi & Sviluppo applicazioni",
        value: "develop",
      },
      {
        text: "Siti Web",
        value: "web",
      },
      {
        text: "Internet of Things e Industria 4.0",
        value: "iot",
      },
      {
        text: "Consulenza tecnica",
        value: "consulting", // <<<
      },
      {
        text: "Digital Signage",
        value: "signage",
      },
      {
        text: "Monitoraggio infrastrutture",
        value: "monitoring",
      },
      {
        text: "Altro...",
        value: "other",
      },
    ],
  }),
  computed: {
    label_messaggio() {
      if (this.messaggio.length == 0) {
        return "Scrivi un messaggio";
      } else {
        return "Messaggio";
      }
    },
  },
  methods: {
    resetErrors() {
      this.err = {
        nome: null,
        azienda: null,
        email: null,
        telefono: null,
        contact_type: null,
        body: null,
        topic: null,
      };
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    },
    validatePhone(phone) {
      if (
        /^(([+]{0,1}\d{2})|\d?)[\s-]?[0-9]{2}[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/.test(
          phone
        )
      ) {
        return true;
      }
      return false;
    },
    validate() {
      // gtag("event", "contact_form_validate", {
      //   contact_type: this.contact_type,
      //   topic: this.topic,
      // });

      this.resetErrors();
      this.disableSend = true;

      console.log("validate");
      let isValid = true;
      if (this.nome.length < 3 || this.nome.length > 20) {
        this.err.nome = "Inserisci nome valido (3-20 caratteri)";
        isValid = false;
      }

      if (
        (this.isAzienda && this.azienda.length < 2) ||
        (this.isAzienda && this.azienda.length > 20)
      ) {
        this.err.azienda = "Inserisci nome azienda valido (3-20 caratteri)";
        isValid = false;
      }

      if (this.contact_type == "Email") {
        if (this.validateEmail(this.email) == false) {
          this.err.email = "Inserisci indirizzo email valido";
          isValid = false;
        }
      }

      if (this.contact_type == "Telefono" || this.contact_type == "Whatsapp") {
        if (this.validatePhone(this.telefono) == false) {
          this.err.telefono = "Inserisci numero telefonico valido";
          isValid = false;
        }
      }

      if (this.topic == null || this.topic == "") {
        this.err.topic = "Seleziona un area d'interesse";
        isValid = false;
      }

      if (this.messaggio.length < 10 || this.messaggio.length > 255) {
        this.err.messaggio = "Inserisci un messaggio valido (10-255 caratteri)";
        isValid = false;
      }

      if (isValid) {
        this.sendMessage();
      } else {
        this.disableSend = false;
      }
    },
    sendMessage() {
      const CF = firebaseFunctions.httpsCallable("siteContact");
      let now = new Date();
      const nowUT = now.getTime() / 1000;
      let ut_lastFeedbackPrompt = localStorage.getItem("ut_lastMessage");

      this.btn_send = "Invio in corso, attendere";

      if (nowUT - ut_lastFeedbackPrompt < 120) {
        alert("Attendi qualche minuto per inviare un nuovo messaggio");
        this.disableSend = false;
      } else {
        // analytics.logEvent("messageSent");
        gtag("event", "contact_form_sent", {
          contact_type: this.contact_type,
          topic: this.topic,
        });

        // gtag("event", "generate_lead", {});

        let payload = {
          nome: this.nome,
          isAzienda: this.isAzienda,
          azienda: this.azienda,
          email: this.email,
          telefono: this.telefono,
          contact_type: this.contact_type,
          topic: this.topic,
          messaggio: this.messaggio,
          altreComunicazioni: this.altreComunicazioni,
          ipdata: this.ipdata,
        };
        console.log(payload);
        this.disableSend = true;
        CF(payload)
          .then((result) => {
            this.result = result;
            if (result.data.status == 0) {
              this.messageSent = true;
            }
            this.btn_send = "Messaggio inviato";
            console.log(result);
          })
          .catch((err) => {
            this.disableSend = false;
            this.btn_send = "Invia";
            console.log("ERR");
            console.log(err);
          });
      }
    },
    test() {
      const CF = firebaseFunctions.httpsCallable("cleanContactsData");
      CF({})
        .then((result) => {
          this.result = result;
          localStorage.setItem("ut_lastMessage", nowUT);
          console.log(result);
        })
        .catch((err) => {
          console.log("ERR");
          console.log(err);
        });
    },
  },
  mounted() {
    gtag("event", "contact_form_open", {});
    // analytics.logEvent("openContacts");
    const url = "https://api.ipify.org";
    axios.get(url).then((response) => {
      this.ipdata = response.data;
      // console.log(this.ipdata);
    });
    const no_header = this.$route.params.noheader;
    if (no_header) {
      this.no_header = true
    }
    const url_topic = this.$route.params.topic;
    if (url_topic) {
      this.topic = url_topic;
    }
  },
  watch: {
    nome() {
      this.err.nome = null;
    },
    azienda() {
      this.err.azienda = null;
    },
    email() {
      this.err.email = null;
    },
    telefono() {
      this.err.telefono = null;
    },
    messaggio() {
      this.err.messaggio = null;
    },
    topic() {
      this.err.topic = null;
    },
  },
};
</script>
<style>
.title-1 {
  word-break: break-all;
  font-weight: bold;
}

.contacts {
  margin-bottom: 100px;
}

.contacts .vue-typer .custom.char {
  color: black !important;
}

.contacts span.caret.custom.vue-typer-caret-solid.idle.pre-type {
  width: 0px;
}

.contacts span.caret.custom.vue-typer-caret-solid.typing {
  width: 8px;
  background-color: rgba(59, 59, 59, 0.986) !important;
}
</style>